<template>
  <div>
    <br>
    <br>
    <base-section-heading title="JOIN THE MHG FAMILY" />
    <div>
      <v-container>
        <v-row class="white elevation-3 test">
          <v-col
            cols="12"
            md="6"
          >
            <base-section-heading
              align="left"
              subtitle="Mulligan Hospitality Ireland"
              tag="h2"
              title="CAREERS AT MHG"
            >
              Five fantastic venues steeped in heritage & tradition located in Dublin.
            </base-section-heading>
            <base-btn
              :block="$vuetify.breakpoint.smAndDown"
              data-aos="flip-up"
              data-aos-duration="1000"
              color="primary"
              href="mailto:info@mulliganhospitality.ie?subject=MHG%20Enquiry"
              large
              target="_blank"
            >
              Contact Us
            </base-btn>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-img
              data-aos="zoom-in"
              data-aos-duration="1000"
              src="@/assets/M&H_27.jpeg"
              class="ml-auto elevation-3"
              height="400"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row class="white elevation-3 test">
          <v-col
            cols="12"
            md="6"
          >
            <base-section-heading
              align="left"
              subtitle="Mulligan Hospitality Ireland"
              tag="h2"
              title="BECOME A PARTNER"
            >
              Five fantastic venues steeped in heritage & tradition located in Dublin.
            </base-section-heading>
            <base-btn
              :block="$vuetify.breakpoint.smAndDown"
              data-aos="flip-up"
              data-aos-duration="1000"
              color="primary"
              href="mailto:info@mulliganhospitality.ie?subject=MHG%20Enquiry"
              large
              target="_blank"
            >
              Contact Us
            </base-btn>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-img
              data-aos="zoom-in"
              data-aos-duration="1000"
              src="@/assets/venues.jpg"
              class="ml-auto elevation-3"
              height="400"
            />
          </v-col>
        </v-row>
      </v-container>
      <br>
      <br>
    </div>
  </div>
</template>

<style scoped>
.test {
  padding: 15px 15px 15px 15px;
  margin: 10px 10px 10px 10px;
}

</style>

<script>
  export default {
    name: 'Hotel',
  }
</script>
